import unveil from './vendor/unveil'
window.unveil = unveil;
import Cookies from 'js-cookie';

//------------------------------------------------------------------------------
// Cookie Notification
//------------------------------------------------------------------------------
window.addEventListener('load', function(){
    if (!Cookies.get('CookieReadNotice')){
        $('#cookie').show();
    }
    $('#cookie-btn-accept').click(function(){
        $('#cookie').hide();
        Cookies.set('CookieReadNotice', true, { expires: 60, path: '/' })
    });
});

$(document).ready(function(){

    // lazy load images
	//------------------------------------------------------------------------------
    $(function() {
		$("img[data-src-mobile]").unveil(300);
	});

    // truncate long text
    function truncateText() {
        $(".truncated").each(function() {
        let text = $(this).text();
            if (text.length > 93) {
                $(this).html(text.substring(0,93)+"...")
            }
        });
    }

    // on load & resize
    $(window).on('load resize', function () {

        // check if header has subnav
        if ($('.header').find('.navbar-sub').length) {
            if ($(window).width() >= 1200) {
                $('.header-module .container').css('padding-top', '240px')
            } else {
                $('.header-module .container').css('padding-top', '170px')
            }
        }

        //------------------------------------------------------------------------------
        // set body margin-bottom to footer height - sticky footer
        //------------------------------------------------------------------------------
        var footerheight = $(".footer").outerHeight();
        var headerheight = $(".header").outerHeight();
        var body = $("body");
        var main = $("main");
        body.css("padding-bottom", footerheight+"px");
        if ($('.car-config-section').length) {
            setTimeout( function() { $('.car-config-section').css("padding-top", $(".header").outerHeight()+"px");}, 100);
        }
        $('.navbar-brand img').css('height', $('.header').height()-10+'px' );

        // close navigation if open
        if ($('.navbar .navbar-collapse').hasClass('show') || $('.navbar .dropdown-menu').hasClass('show'))  {
            $('.navbar-collapse').collapse('hide');
            $(".header-wrapper").load(location.href + " .header");
            $(".navbar-nav .nav-item").removeClass('active');
        }

        //------------------------------------------------------------------------------
        // anchor link offset fix
        //------------------------------------------------------------------------------
        if ($('.anchor').length) {
            $('.anchor').css('padding-top', headerHeight + 10);
            $('.anchor').css('margin-top', -headerHeight - 10);
        }
    });

    var windowHeight = $(window).height();
    var windowWidth = $(window).width();
    var headerHeight = $(".header").outerHeight();
    var headerImage = $(".header").css('background-image');

    // on load
    $(window).on('load', function () {

        // truncate long text
        truncateText();

        // calculate reading time
        function calcRT () {
            const wordsPerMinute = 200; // Average case.
            let resLength = 0;
            $('.rt-text').each(function(){
                let textElement = $(this).text();
                let textLength = textElement.split(' ').length; // Split by words
                if(textLength > 0){
                    resLength += textLength;
                }
            });
            let value = Math.ceil(resLength / wordsPerMinute);
            if (value > 0) {
                let textRT = `${value} MIN.`;
                $("#rt-value").text(textRT);
            }
        };
        calcRT();

        // center three column element if less than 3
        let cols = $(".three-col div[class*='col-']");
        if (cols.length < 3) {
            $(".three-col .row").css('justify-content', 'center');
        }

        // navbar
        $('#navbarNavDropdown .nav-item').on('shown.bs.collapse', function(e) {
            if ($(window).width() >= 1200) {
                $(".header").stop().animate({height:'90%'},400, function() {
                    $(this).find('.nav-dropdown-menu').css("height", ($(".header").outerHeight() - headerHeight));
                });
                // hide subnav
                if ($('.navbar-sub').length > 0) {
                    $('.navbar-sub').hide();
                }
            } else {
                $(this).addClass('active');
                $(".navbar-collapse .nav-item:not(.active)").hide();
                $(".service-bar-mobile .nav-collapse-col").removeClass('mt-40');

                if ($(this).find('.nav-dropdown-menu-model').length > 0) {
                    $(".navbar-collapse").css("background", "white");
                } else {
                    $(".navbar-collapse").css("background", "none");
                }
            }
            if (headerImage !== "none") {
                $(".header").css("background-image", headerImage);
                $(".header").css("background-repeat", "no-repeat");
                $(".header").css("background-size", "cover");
            } else {
                $(".header").css("background", "black");
            }
        });

        $('#navbarNavDropdown .nav-item').on('hide.bs.collapse', function(e) {
            if ($(window).width() >= 1200) {
                $(".navbar-collapse .nav-dropdown").each(function() {
                    setTimeout(function(){
                        if ($(this).attr("aria-expanded") == "true") {
                            //$(".header").stop().animate({height:'90%'},300);
                            return false;
                        } else {
                            $(".header").css("height", "auto");
                        }
                    }, 100);
                });
            } else {
                $(".navbar-collapse .nav-item").show();
                $(".navbar-collapse .nav-item").removeClass('active');
                $(".service-bar-mobile .nav-collapse-col").addClass('mt-40');
                $(".navbar-collapse").css("background", "white");
            }

            if (($(".header-module .header-module-img").length > 0 || $(".header-module .header-module-video").length > 0) && $(window).scrollTop() === 0) {
                $(".header").css("background", "transparent");
            } else if ((headerImage !== "none") && $(window).scrollTop() === 0) {
                $(".header").css("height", "auto");
            }
        });

        $('#navbarNavDropdown .nav-item').on('hidden.bs.collapse', function(e) {
            if ($(window).width() >= 1200) {
                // show subnav
                setTimeout(function(){
                    if ($('.navbar-sub').length > 0) {
                        $('.navbar-sub').show();
                    }
                }, 350);
            }
        });

        $(document).click(function (e) {
            if ($(e.target).closest("#navbarNavDropdown .nav-dropdown-menu").length === 0) {
                $('#navbarNavDropdown .nav-dropdown-menu').collapse('hide');
            }
        });

        $(".navbar-toggler").click(function(e) {
            e.stopPropagation();
        });

        $(".navbar-toggler .navbar-toggler-cta").click(function(e) {
            $(this).parents().eq(2).find('.navbar-collapse').collapse('toggle');
            if ($(".language-switch-mobile").hasClass('d-none')){
                $(".language-switch-mobile").removeClass('d-none');
                $(".navbar-brand").addClass('d-none');
                if ($('.navbar-sub').length > 0) {
                    $('.navbar-sub').addClass('d-none');
                }
                setTimeout(function(){
                    var updatedheaderHeight = $(".header").outerHeight();
                    if (updatedheaderHeight >= windowHeight || windowWidth < 768) {
                        $(".header").css("height", "100%");
                        if ($('.navbar-sub').length > 0) {
                            $(".navbar-collapse").css("height", ($(".header").outerHeight() + 70 - headerHeight)+'px');
                        } else {
                            $(".navbar-collapse").css("height", ($(".header").outerHeight() - headerHeight)+'px');
                        }
                    } else if (windowWidth >= 768 && windowWidth < 1200) {
                        $(".header").css("height", updatedheaderHeight);
                        if ($('.navbar-sub').length > 0) {
                            $(".navbar-collapse").css("height", ($(".header").outerHeight() + 70 - headerHeight)+'px');
                        } else {
                            $(".navbar-collapse").css("height", ($(".header").outerHeight() - headerHeight)+'px');
                        }
                    }
                }, 350); // do not change number

            } else {
                $(".language-switch-mobile").addClass('d-none');
                $(".header").css("height", "auto");

                setTimeout(function(){
                    $(".navbar-brand").removeClass('d-none');
                    if ($('.navbar-sub').length > 0) {
                        $('.navbar-sub').removeClass('d-none');
                    }
                    $('.navbar-brand img').stop().animate({height:$('.header').height()+'px'},200);
                }, 300);
            }
        });

        // language switch
        $(".language-switch-mobile a").click(function(e) {
            // remove classes from all
            $(".language-switch-mobile a").removeClass("current");
            // add class to the one we clicked
            $(this).addClass("current");
        });

        // magazin nav
        $(".nav-list a.nav-category").click(function(e) {
            $(".nav-list a.nav-category").removeClass('active');
            // add class to the one we clicked
            $(this).toggleClass("active");
        });

        // header module
        if (($(".header-module .header-module-img").length > 0 || $(".header-module .header-module-video").length > 0) && $(window).scrollTop() === 0) {
            $(".header").css("background", "transparent");
        }

        // magazin header
        if ($(".header-module.magazin-header .header-module-img").length == 0 && $(".header-module.magazin-header .header-module-video").length == 0) {
            $(".header-module.magazin-header").find(".text-white").removeClass("text-white");
        }

        /* car configurator info hover
        $(".car-configurator .header-module-info").on({
            mouseenter: function () {
                var icon = $(this).find('.icon-modelinfo');
                icon.attr("class", icon.attr("class").replace("white", "red"));
            },
            mouseleave: function () {
                var icon = $(this).find('.icon-modelinfo');
                icon.attr("class", icon.attr("class").replace("red", "white"));
            }
        });*/
    });

    // on scroll
    var lastScrollTop = 0;
    var st;

    $(window).on("scroll",function(e){
        st = $(this).scrollTop();
        var serviceBar = $('.service-bar');
        var navBar = $('.navbar-sup');
        var subnavBar = $('.navbar-sub');

        // navbar
        // check if no dropdown is open
        $(".navbar-collapse .nav-dropdown").each(function() {
            if ($(this).attr("aria-expanded") == "true") {
                return false;
            } else {
                setTimeout(function(){
                    if (st > lastScrollTop){

                      if (($(subnavBar).length > 0) && (!$('.navbar-collapse').hasClass('show'))) {
                        $('.navbar-brand img').hide();
                        $('.navbar-brand img').stop().animate({height:'0px'},500);
                        serviceBar.slideUp(500,"linear", function () {});
                        navBar.slideUp(500,"linear", function () {
                            headerHeight = $('.header').outerHeight();
                        });
                      } else {
                        serviceBar.slideUp(500,"linear", function () {
                            $('.navbar-brand img').stop().animate({height:$('.header').height() - 10 +'px'},200);
                            headerHeight = $('.header').outerHeight();
                        });
                      }

                      if ($(".header-module .header-module-img").length > 0 || $(".header-module .header-module-video").length > 0) {
                        if (headerImage !== "none") {
                            $(".header").css("background-image", headerImage);
                            $(".header").css("background-repeat", "no-repeat");
                            $(".header").css("background-size", "cover");
                        } else {
                            $(".header").css("background", "black");
                        }
                      }
                    } else if (st < lastScrollTop && st == 0) {

                      if (($(subnavBar).length > 0) && (!$('.navbar-collapse').hasClass('show'))) {
                        serviceBar.slideDown(500,"linear", function () {
                            $('.navbar-brand img').show();
                            $('.navbar-brand img').stop().animate({height:$('.service-bar').height() + $('.navbar-sup').height() + 20 +'px'},200);
                        });
                        navBar.slideDown(500,"linear", function () {
                            headerHeight = $('.header').outerHeight();
                        });
                      } else {
                        serviceBar.slideDown(500,"linear", function () {
                            $('.navbar-brand img').stop().animate({height:$('.header').height() -10 +'px'},200);
                        });
                      }

                      if ($(".header-module .header-module-img").length > 0 || $(".header-module .header-module-video").length > 0) {
                        $(".header").css("background", "transparent");
                      }
                    }
                    lastScrollTop = st;
                }, 100);
            }
        });
    });

    //------------------------------------------------------------------------------
    // facts: set color of first word red
    //------------------------------------------------------------------------------
    $('.fact .fact-text').each(function(){
        var content = $(this);
        content.html(content.html().replace(/^([\w'-]+)/, '<span class="text-red">$1</span>'));
   });


    //------------------------------------------------------------------------------
    // show more dealer contacts on button click
    //------------------------------------------------------------------------------
   $(".alle-anzeige").on("click", function(){
        $('.haendler-team-item:hidden').slice(0,).slideDown({
            start: function () {
                $(this).css({
                  display: "flex"
                })
            }
        });
        $(".alle-anzeige").css("display", "none");
   });

    //------------------------------------------------------------------------------
    // show selected item for haendler dropdown
    //------------------------------------------------------------------------------
   $(document).on('click', '[data-efdel="dealersSearch"] .dropdown-menu .dropdown-item', function(event) {
        var $target = $( event.currentTarget ),
        haendlerVal = $target.parents().eq(3).find('.haendler-val'),
        haendlerName = $target.find('[data-efd-label="dealer"] [data-efdtemfi="name"]').text(),
        haendlerCity = $target.find('[data-efd-label="dealer"] [data-efdtemfi="city"]').text(),
        txt = haendlerName+" "+haendlerCity;

        // change filter val text
        if (haendlerVal.find('.txt').length !==0 ) {
                haendlerVal.find('.txt').remove();
                haendlerVal.append('<span class="txt">'+txt+'</span>');

        } else {
            haendlerVal.append('<span class="txt">'+txt+'</span>');
            haendlerVal.find('.initial').hide();
        }
    });

    //------------------------------------------------------------------------------
    // pricebox mobile collapse element
    //------------------------------------------------------------------------------
   $('.collapse-pricebox').on('show.bs.collapse', function (e) {
        $(this).parent().find('.lower-collapse-icon').css({"display": "none"});
        $(this).parent().find('.upper-collapse-icon').css({"transform" : "rotate(-90deg)", "right": "32px"});
        $(this).parent().find('.price-tag').css({"display" : "inline-block"});
    });

    $('.collapse-pricebox').on('hide.bs.collapse', function (e) {
        $(this).parent().find('.lower-collapse-icon').css({"display": "inline-block"});
        $(this).parent().find('.price-tag').css({"display" : "none"});
    });

})

